import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { isPlatformBrowser } from '@angular/common';
import { OAuthService, AuthConfig } from 'angular-oauth2-oidc';
import { Observable, of } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { truncate } from 'fs';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private apiUrl = 'https://your-api.com/auth/login'; // Replace with your backend API
  isBrowser: boolean;
  private authConfig: AuthConfig;
  private redirectUrl: string | null = null;
  authCode: string = '';

  constructor(
    private http: HttpClient,
    private oauthService: OAuthService,
    private router: Router,
    private route: ActivatedRoute, // Inject ActivatedRoute to access query params
    @Inject(PLATFORM_ID) private platformId: object
  ) {
    this.isBrowser = isPlatformBrowser(this.platformId);

    this.authConfig = {
        issuer: 'https://accounts.google.com',
        redirectUri: 'https://www.lambdafin.com', // Only set in the browser
        clientId: '1098306520146-20g4aaroiqiv3fpg80bcjdnmbsjhsp0a.apps.googleusercontent.com',  // Replace with your actual client ID
        scope: 'openid profile email',
        responseType: 'code',
        dummyClientSecret:'',
        strictDiscoveryDocumentValidation: false,
        requestAccessToken: true
      };

    if (this.isBrowser) {
      this.oauthService.configure(this.authConfig);
      this.oauthService.setupAutomaticSilentRefresh();
      this.oauthService.loadDiscoveryDocumentAndTryLogin()
    }
  }

  setRedirectUrl(url: string): void {
    this.redirectUrl = url;
  }

  getRedirectUrl(): string | null {
    return this.redirectUrl;
  }

  login(credentials: { email: string; password: string }): Observable<any> {
    if (!this.isBrowser) return new Observable(observer => observer.error('Login not available on server-side'));
    return this.http.post<any>(this.apiUrl, credentials);
  }

  signInWithGoogle(): void {
    if (this.isBrowser) {
      this.oauthService.initImplicitFlow();  // Start OAuth flow
    }
  }
  
  // Function to get the authorization code from the query parameters (without Observable)
  getAuthorizationCode(): string | null {
    return this.route.snapshot.queryParamMap.get('code');
  }

  async handleLogin() {
    try {
      // Wait for the OAuth service to handle the login and retrieve the token
      await this.oauthService.loadDiscoveryDocumentAndTryLogin();
  
      // Now check if we have a valid access token
      const accessToken = this.oauthService.getAccessToken();
      console.log(accessToken);
  
      if (accessToken) {
        console.log('Login successful!');
        // You can now send the access token to your backend to authenticate the user
        this.http.post<any>('https://your-api.com/auth/google-login', { accessToken })
          .subscribe(response => {
            console.log('Backend login success:', response);
            // Redirect user to the desired page (dashboard, etc.)
            this.router.navigateByUrl('/dashboard');
          }, error => {
            console.error('Backend login failed:', error);
          });
      } else {
        console.error('Login failed: No token received');
      }
    } catch (error) {
      console.error('Error during login process:', error);
    }
  }
  

  exchangeAuthCodeForToken(authCode: string) {
    const clientId = '1098306520146-20g4aaroiqiv3fpg80bcjdnmbsjhsp0a.apps.googleusercontent.com';
    const clientSecret = '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';  // **Not secure for production**
    const redirectUri = 'https://www.lambdafin.com';

    const tokenRequest = new HttpParams()
      .set('code', authCode)
      .set('client_id', clientId)
      .set('client_secret', clientSecret)
      .set('redirect_uri', redirectUri)
      .set('grant_type', 'authorization_code');
      const headers = new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
      });
  
  return this.http.post('https://oauth2.example.com/token', tokenRequest, { headers });
  }

  getGoogleToken(): string | null {
    return this.authCode !== '' ? this.authCode: this.oauthService.getAccessToken();
  }

  // Save the code in memory
  setAuthorizationCode(code: string): void {
    this.authCode = code;
  }
}
